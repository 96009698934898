/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import PostContent from '../../components/post-content';
/**
 * Internal dependencies.
 */
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import { getOgImage } from "./../../utils/functions";
import ContactForm from '@blocks/contact-form';

import './style.scss';

/**
 * Webinar Single Post.
 *
 * @param {Object} props Props
 *
 * @return {*}
 */
const GuideSingle = (props) => {
	const { pageContext } = props;
	const {
		title,
		contentRaw,
		content,
		seo,
		uri,
		pageMeta,
		reusableBlocks,
		featuredImage
	} = pageContext;

	const openGraphImage = getOgImage(seo) || featuredImage?.node?.guid;
	let theme = (pageMeta && pageMeta.theme) ? pageMeta.theme : 'light';
	return (
		<Layout title={title} uri={uri} theme={theme} className="page">
			<SEO
				title={title}
				seoData={seo}
				uri={uri}
				header={{ siteTitle: 'OpenWeb' }}
				openGraphImage={openGraphImage}
			/>
			<PostContent content={content} contentRaw={contentRaw} reusableBlocks={reusableBlocks} />
			<ContactForm />
		</Layout>
	)
};

GuideSingle.propTypes = {
	pageContext: PropTypes.object,
	uri: PropTypes.string,
};

export default GuideSingle;
