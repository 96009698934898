/**
 * External dependencies.
 */
import React from 'react';
import gql from 'graphql-tag';
import queryString from 'query-string';
import { useQuery } from "@apollo/react-hooks";

import './style.scss';
import Page from '../page';
import Webinar from '../webinar-single';
import Post from '../single';
import CaseStudy from '../case-study-single';
import Guide from '../guide-single';
import PodcastSingle from '../podcast-single';
import Podcast from '../podcasts-page';

const Preview = ({ location }) => {
    const queryParams = queryString.parse(location.search);
    const { id, preview_password, post_type } = queryParams ?? {};
    // https://staging-www.openweb.com/preview?id=11848&preview_password=8O4KSZrP36sAJwxkCzKxFqtouWoW7EZE

    let pageMeta = ``;
    const contentRawPostTypess = ['page', 'webinar', 'guide', 'podcast']
    const contentRaw = contentRawPostTypess.indexOf(post_type) !== -1 ? `contentRaw` : ``;
    if (post_type === 'page') {
        pageMeta = `pageMeta 
            { 
                theme
            }`;
    }


    const GET_PAGES = gql`
        query GET_PAGES {
            ${post_type}( id: ${id}, idType: DATABASE_ID ) {
                uri
                id
                title
                content
                ${contentRaw}
                uri
                template {
                    templateName
                }
                ${pageMeta}
                seo {
                    canonical
                    title
                    metaDesc
                    focuskw
                    metaRobotsNoindex
                    metaRobotsNofollow
                    opengraphAuthor
                    opengraphTitle
                    opengraphDescription
                    opengraphImage {
                        sourceUrl
                    }
                    opengraphUrl
                    opengraphSiteName
                    opengraphPublishedTime
                    opengraphModifiedTime
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        sourceUrl
                    }
                    schema {
                        raw
                    }
                  }
            }
            reusableBlocks(first: 5000) {
                edges {
                    node {
                        content
                        id
                    }
                }
            }
        }
    `;


    const GET_POST = gql`
    query GET_POST {
      ${post_type}( id: ${id}, idType: DATABASE_ID ) {
            status
            id
            databaseId
            title
            uri
            slug
            date
            excerpt
            content
            owHidePublishDate
            seo {
                canonical
                title
                metaDesc
                focuskw
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphAuthor
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    sourceUrl
                }
                opengraphUrl
                opengraphSiteName
                opengraphPublishedTime
                opengraphModifiedTime
                twitterTitle
                twitterDescription
                twitterImage {
                    sourceUrl
                }
                schema {
                    raw
                }
            }
            categories(first: 3) {
                edges {
                    node {
                    name
                    uri
                    posts(first: 3) {
                        edges {
                        node {
                            id
                            databaseId
                            title
                            uri
                            date
                            categories {
                            edges {
                                node {
                                name
                                uri
                                }
                            }
                            }
                            author {
                            node {
                                name
                                uri
                            }
                            }
                            large: featuredImage {
                            node {
                                sourceUrl(size: POST_THUMBNAIL_LANDSCAPE)
                                title
                                altText
                                mediaDetails {
                                width
                                height
                                }
                            }
                            }
                            medium: featuredImage {
                            node {
                                sourceUrl(size: MEDIUM_LANDSCAPE)
                                title
                                altText
                                mediaDetails {
                                width
                                height
                                }
                            }
                            }
                            thumbnail: featuredImage {
                            node {
                                sourceUrl(size: THUMBNAIL)
                                title
                                altText
                                mediaDetails {
                                width
                                height
                                }
                            }
                            }
                            multipleAuthors {
                            authors {
                                name
                                uri
                            }
                            }
                        }
                        }
                    }
                    }
                }
            }
            author {
                node {
                    name
                    uri
                }
            }
            large: featuredImage {
                node {
                    sourceUrl(size: POST_THUMBNAIL_LANDSCAPE)
                    title
                    altText
                    mediaDetails {
                        width
                        height
                    }
                }
            }
            medium: featuredImage {
                node {
                    sourceUrl(size: MEDIUM_LANDSCAPE)
                    title
                    altText
                    mediaDetails {
                    width
                    height
                    }
                }
            }
            thumbnail: featuredImage {
                node {
                    sourceUrl(size: THUMBNAIL)
                    title
                    altText
                    mediaDetails {
                    width
                    height
                    }
                }
            }
            multipleAuthors {
                authors {
                    name
                    uri
                }
            }
            hubSpotDownloadFormPageMeta {
                hubspotDownloadForm {
                    downloadLabel
                    downloadLink
                    fieldGroupName
                    formHeadline
                    formId
                    formPortalid
                    thankYouMessage
                }
            }
        }
    }
    `;


    const GET_CASE_STUDY = gql`
    query GET_CASE_STUDY {
        ${post_type}( id: ${id}, idType: DATABASE_ID ) {
                id
                title
                uri
                date
                excerpt
                content
                seo {
                    canonical
                    title
                    metaDesc
                    focuskw
                    metaRobotsNoindex
                    metaRobotsNofollow
                    opengraphAuthor
                    opengraphDescription
                    opengraphTitle
                    opengraphDescription
                    opengraphImage {
                        sourceUrl
                    }
                    opengraphUrl
                    opengraphSiteName
                    opengraphPublishedTime
                    opengraphModifiedTime
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        sourceUrl
                    }
                    schema {
                        raw
                    }
                }
                ourPartnersMeta {
                    fieldGroupName
                    headline
                    ourPartners
                }
                casestudyPostMeta {
                    fieldGroupName
                    caseStudy {
                        fieldGroupName
                        formHeadline
                        formPortalid
                        formId
                        thankYouMessage
                        downloadLabel
                        downloadLink
                    }
                }
            }
            page(id: "home-page", idType: URI) {
                title
                seo {
                    canonical
                    title
                    metaDesc
                    focuskw
                    metaRobotsNoindex
                    metaRobotsNofollow
                    opengraphAuthor
                    opengraphDescription
                    opengraphTitle
                    opengraphDescription
                    opengraphImage {
                        sourceUrl
                    }
                    opengraphUrl
                    opengraphSiteName
                    opengraphPublishedTime
                    opengraphModifiedTime
                    twitterTitle
                    twitterDescription
                    twitterImage {
                        sourceUrl
                    }
                }
                frontPageMeta {
                    sectionSix {
                        fieldGroupName
                        logos {
                            altText
                            sourceUrl
                            title
                            mediaDetails {
                                height
                                width
                            }
                        }
                    }
                }
            }
    }
    `;


    const GET_WEBINAR = gql`
    query GET_WEBINAR {
        ${post_type}( id: ${id}, idType: DATABASE_ID ) {
            id
            title
            uri
            date
            excerpt
            content
            contentRaw
            featuredImage {
                node {
                    guid
                }
            }
            seo {
                canonical
                title
                metaDesc
                focuskw
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphAuthor
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    sourceUrl
                }
                opengraphUrl
                opengraphSiteName
                opengraphPublishedTime
                opengraphModifiedTime
                twitterTitle
                twitterDescription
                twitterImage {
                    sourceUrl
                }
                schema {
                    raw
                }
            }
        }
        reusableBlocks(first: 5000) {
            edges {
                node {
                    content
                    id
                }
            }
        }
    }
    `;

    const GET_GUIDES = gql`
    query GET_GUIDES {
        ${post_type}( id: ${id}, idType: DATABASE_ID ) {
            title
            date
            excerpt
            uri
            contentRaw
            content
            contentTypeName
            custom_fields {
                guideDetailsGroup {
                    downloadLink {
                        target
                        title
                        url
                    }
                    gaAction
                    fieldGroupName
                    gaCategory
                    gaFieldsObject
                    gaLabel
                    gaValue
                }
            }
            featuredImage {
                node {
                    sizes
                    sourceUrl
                    srcSet
                    title
                }
            }
        }
        reusableBlocks(first: 5000) {
            edges {
                node {
                    content
                    id
                }
            }
        }
    }
    `;




    const GET_PODCAST = gql`
    query GET_PODCAST {
        ${post_type}( id: ${id}, idType: DATABASE_ID ) {
            title
            uri
            date
            excerpt
            content
            contentRaw
            featuredImage {
                node {
                    guid
                }
            }
            seo {
                canonical
                title
                metaDesc
                focuskw
                metaRobotsNoindex
                metaRobotsNofollow
                opengraphAuthor
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    sourceUrl
                }
                opengraphUrl
                opengraphSiteName
                opengraphPublishedTime
                opengraphModifiedTime
                twitterTitle
                twitterDescription
                twitterImage {
                    sourceUrl
                }
                schema {
                    raw
                }
            }
        }
        reusableBlocks(first: 5000) {
            edges {
                node {
                    content
                    id
                }
            }
        }
    }
    `;

    let QUERY = ''; // post_type === 'post' ? GET_POST : GET_PAGES;
    // console.log(post_type)
    switch (post_type) {
        case 'post':
            QUERY = GET_POST;
            break;
        case 'caseStudy':
            QUERY = GET_CASE_STUDY;
            break;
        case 'webinar':
            QUERY = GET_WEBINAR;
            break;
        case 'guide':
            QUERY = GET_GUIDES;
            break;
        case 'podcast':
            QUERY = GET_PODCAST;
            break;
        default:
            QUERY = GET_PAGES;
            break;
    }

    const query = useQuery(QUERY, {
        context: {
            headers: {
                "Content-Type": "application/json",
                "X-OW-Preview-Password": preview_password ?? null,
            }
        },
        enabled: !!location,
        onError: (err) => { console.log('our error', err) }
    });
    const { loading, error, data } = query;

    if (loading) {
        return (
            <div className="ow-preview-center-align-content">
                <h3>Loading Preview...</h3>
            </div>
        )
    }
    // console.log('error', error);
    // console.log(data);

    if (!error) {
        if (post_type === 'page' && data?.page) {
            if (data?.page?.uri === "/podcast/") {
                console.log('prevew podcast', data);
                return <Podcast pageContext={{ ...data.page, reusableBlocks: data?.reusableBlocks }} />;
            }
            console.log('prevew page', data);

            return <Page pageContext={data.page} />;
        }
        if (post_type === 'webinar' && data?.webinar) {
            return <Webinar pageContext={data.webinar} />;
        }
        if (post_type === 'post' && data?.post) {
            return <Post pageContext={data.post} uri={data.post.uri} />;
        }
        if (post_type === 'caseStudy' && data?.caseStudy) {
            return <CaseStudy pageContext={{ ...data.caseStudy, page: data?.page }} />;
        }
        if (post_type === 'guide' && data?.guide) {
            return <Guide pageContext={{ ...data.guide, page: data?.guide, reusableBlocks: data?.reusableBlocks }} />;
        }
        if (post_type === 'podcast' && data?.guide) {
            return <PodcastSingle pageContext={{ ...data.podcast, page: data?.podcast, reusableBlocks: data?.reusableBlocks }} />;
        }
    }


    return (
        <div className="ow-preview-center-align-content">
            <h3>Undable to preview the content</h3>
        </div>
    )
};

export default Preview;
